const lpC = {};

/**
 *
 * @param {*} data
 * div: nombre div
 *
 */

lpC["prev"] = function (appC) {
  const appC2 = document.getElementById("optionsdynmodal")["app"];

  lpC["appC"] = appC;
  lpC["tags"] = {};
  appC2.getTagH({
    html: "core.html",
    get: "innerHTML",
    extra: {},
    fnc: function (html, extra) {
      const dd = document.createElement("div");
      dd.innerHTML = html.innerHTML;
      setTimeout(
        (dd) => {
          const trs = dd.getElementsByTagName("tr");
          for (const tr of trs) {
            if (tr.getAttribute("type")) {
              const _tr = tr.cloneNode(true);
              switch (_tr.getAttribute("type")) {
                case "option":
                  break;
                case "options":
                  break;
              }
              lpC.tags[tr.getAttribute("type")] = _tr;
            }
          }
        },
        1,
        dd
      );
    },
  });
};

lpC["tipo_resultados"] = {
  build: function (data) {
    const tr = document.getElementById("tr_re");
    //clonar el tr (tr_newtr)
    const newtr = document.getElementById("tr_newtr").cloneNode(true);
    newtr.id = "";
    newtr.style.display = "";

    newtr.getElementsByTagName("span")[0].setAttribute("_id", data._id);
    newtr.getElementsByTagName("span")[2].innerHTML =
      "&nbsp;" + data.text + "&nbsp";

    //dentro del td añadiremos el div correspondiente al tipo de resultado
    const ddiv = document.getElementById("tr_" + data._id).cloneNode(true);
    ddiv.id = "";
    ddiv.style.display = "";

    if (data.hasOwnProperty("values")) {
      switch (data._id) {
        case "64f9971d6f21bf0349caa824":
          ddiv.getElementsByTagName("input")[0].value = data.values.texto;
          break;
        case "64f9972d6f21bf0349caa828":
          ddiv.getElementsByTagName("input")[0].value = data.values.texto;
          ddiv.getElementsByTagName("input")[1].value = data.values.valor;
          ddiv.getElementsByTagName("input")[2].value = data.values.tolerancia1;
          ddiv.getElementsByTagName("input")[3].value = data.values.tolerancia2;
          break;
        case "64f997426f21bf0349caa82c":
          ddiv.getElementsByTagName("input")[0].value = data.values.texto;
          ddiv.getElementsByTagName("input")[1].value = data.values.minimo;
          ddiv.getElementsByTagName("input")[2].value = data.values.maximo;
          break;
        case "64f997f56f21bf0349caa85f":
          ddiv.getElementsByTagName("input")[0].value = data.values.texto;
          ddiv.getElementsByTagName("input")[1].value = data.values.diferencial;
          break;
      }
    }

    newtr.getElementsByTagName("td")[0].appendChild(ddiv);
    tr.parentNode.insertBefore(newtr, tr);
  },
};

lpC["delete"] = async function () {
  const itemUpd = await lpC.appC.deleteDDItem_2(lpC["idItem"]);

  const DivTable = document.getElementById(lpC["divTableId"]);
  DivTable.parentElement.removeChild(DivTable);
  lpC.appC.code_build["Table"](lpC.appC, lpC.rebuild_item);
};

lpC["submit"] = async function () {
  // appC = document.getElementById("fnct")['app'];

  let data;
  if (lpC.data.hasOwnProperty("row")) {
    data = lpC.data.row;
  } else {
    data = lpC["newItem"];
  }

  for (const fld of lpC.data.fields) {
    console.log(fld.parameters.typegeneral);
    switch (fld.parameters.typegeneral) {
      case "check":
        data.parameters[fld.name + "general"] = document.getElementById(
          fld.name + "general"
        ).checked;
        break;
      case "special":
        {
          let info = {};
          const tr = document.getElementById(fld.name + "general");

          switch (fld.parameters.specialgeneral) {
            case "tipo_resultados":
              info["items"] = [];
              //los trs son los tipos seleccionados (menos el último)
              const trs = tr.getElementsByTagName("tr");
              for (const each of trs) {
                const sp = each.getElementsByTagName("span");
                if (sp.length > 0) {
                  const _id = sp[0].getAttribute("_id");
                  const res = { _id };
                  switch (_id) {
                    case "64f9971d6f21bf0349caa824": //Booleano con texto explicativo
                      res["texto"] =
                        each.getElementsByTagName("input")[0].value;
                      break;
                    case "64f9972d6f21bf0349caa828": //numerico con tolerancia
                      res["texto"] =
                        each.getElementsByTagName("input")[0].value;
                      res["valor"] =
                        each.getElementsByTagName("input")[1].value;
                      res["tolerancia1"] =
                        each.getElementsByTagName("input")[2].value;
                      res["tolerancia2"] =
                        each.getElementsByTagName("input")[3].value;
                      break;
                    case "64f997426f21bf0349caa82c": //numérico con intervalo
                      res["texto"] =
                        each.getElementsByTagName("input")[0].value;
                      res["minimo"] =
                        each.getElementsByTagName("input")[1].value;
                      res["maximo"] =
                        each.getElementsByTagName("input")[2].value;
                      break;
                    case "64f997f56f21bf0349caa85f": //numérico con valor esperado
                      res["texto"] =
                        each.getElementsByTagName("input")[0].value;
                      res["diferencial"] =
                        each.getElementsByTagName("input")[1].value;

                      break;
                  }
                  if (Object.keys(res).length > 1) {
                    info["items"].push(res);
                  }
                }
              }
              break;
            case "check":
              info = tr.getElementsByTagName("input")[0].checked;
              break;
            case "periodicidad":
              const periodo = tr.getElementsByTagName("select")[0].value;
              const num = tr.getElementsByTagName("input")[0].value;

              if (isNaN(num)) {
                console.log("no puedes");
                alert("no puedes");
                return;
              } else {
                info["periodo"] = periodo;
                info["num"] = num;

                if (periodo === "none") {
                  info["num"] = "";
                }
              }

              break;
          }
          data.parameters[fld.name + "general"] = info;
        }
        break;
      case "string":
        data.parameters[fld.name + "general"] = document.getElementById(
          fld.name + "general"
        ).value;
        break;
      case "options":
        const info = { type: [] };
        const tr = document.getElementById(fld.name + "general");
        const spans = tr.getElementsByTagName("span");
        for (const span of spans) {
          if (span.getAttribute("_id")) {
            info.type.push(span.getAttribute("_id"));
          }
        }
        data.parameters[fld.name + "general"] = info;
        break;
      case "option":
        {
          const info = { type: "" };
          const tr = document.getElementById(fld.name + "general");
          const select = tr.getElementsByTagName("select")[0];
          info.type = select.value;
          data.parameters[fld.name + "general"] = info;
        }
        break;
    }
  }

  if (lpC.data.hasOwnProperty("row")) {
    const itemUpd = await lpC.appC.saveUpdDDItem_2(data);
  } else {
    const itemUpd = await lpC.appC.saveNewDDItem_2(data);
  }

  const DivTable = document.getElementById(lpC["divTableId"]);
  DivTable.parentElement.removeChild(DivTable);
  lpC.appC.code_build["Table"](lpC.appC, lpC.rebuild_item);
};

lpC["build"] = function (data) {
  lpC["data"] = data;

  const appC2 = document.getElementById("optionsdynmodal")["app"];

  switch (data.type) {
    case "DD_MD":
      const tbl = document.getElementById("tblgeneralmod");

      // document.getElementById('btn_save_changes').addEventListener("click", function () {
      //    console.log("submittt");
      //    lpC['submit']();
      // });

      tbl.innerHTML = "";

      //voy a reordenar según tipos
      const fldSort = [];
      let info = {};
      let option = {};
      for (const fld of data.fields) {
        switch (fld.parameters.typegeneral) {
          case "info":
            info = fld;
            break;
          case "option":
            option = fld;
            break;
          default:
            fldSort.push(fld);
            break;
        }
      }
      if (Object.keys(info).length > 0) {
        fldSort.push(info);
      }
      if (Object.keys(option).length > 0) {
        fldSort.push(option);
      }

      //paso por los fields
      for (const fld of fldSort) {
        switch (fld.parameters.typegeneral) {
          case "info":
            //son los datos del equipo (características)

            const trsup = document.createElement("tr");
            const tdsup = document.createElement("td");

            tdsup.style.textAlign = "center";
            tdsup.colSpan = 2;
            tdsup.innerText = "----- Características comunes -----";

            trsup.appendChild(tdsup);
            tbl.appendChild(trsup);

            //vamos a por los all
            for (const each of main.app["DDFields"]["all"].filter((each) =>
              each.parameters.Tipos_de_equipogeneral.type.includes("all")
            )) {
              const _tr_s = lpC.tags["string"].cloneNode(true);
              const label = _tr_s.getElementsByTagName("label")[0];
              const input = _tr_s.getElementsByTagName("input")[0];
              input.id = each._id;
              label.innerHTML = each.parameters.Namegeneral + ":&nbsp;";
              if (data.hasOwnProperty("row")) {
                try {
                  input.value = data.row.parameters[each._id];
                } catch (error) {}
              }
              tbl.appendChild(_tr_s);
            }

            break;
          case "option":
            {
              const _tr = lpC.tags["option"].cloneNode(true);

              const label = _tr.getElementsByTagName("label")[0];
              label.innerHTML = fld.parameters.namegeneral + ":&nbsp;";

              _tr.id = fld.name + "general";
              const parms = fld.parameters.optiongeneral;

              const DDTables = _tr.getElementsByTagName("select")[0];
              DDTables.addEventListener("click", function () {
                console.log(this.value);
                //repasar los trs
                const div = document.getElementById("generalmod");
                const trs = div.getElementsByTagName("tr");

                //mostrat/ocultar los tr
                for (const each of trs) {
                  if (each.getAttribute("tipos")) {
                    if (each.getAttribute("tipos").includes(this.value)) {
                      each.style.display = "";
                    } else {
                      each.style.display = "none";
                    }
                  }
                }
              });

              appC2.lp_query(
                {
                  type: "querySelect",
                  table: "modelizer_items",
                  query: { type: "5f994d5908425a1078a1b7b2", _id: parms.table },
                },
                async function (rett) {
                  //acceso primer select
                  const FLD = await appC2.lp_queryAsync({
                    type: "querySelect",
                    table: "modelizer_items",
                    query: { _id: parms.field },
                  });

                  appC2.lp_query(
                    {
                      type: "querySelect",
                      table: "modelizer_items",
                      query: {
                        type: "5fbf3934eb78a202dc26d321",
                        parent: rett[0]["_id"],
                      },
                    },
                    function (retTE) {
                      //acceso primer select
                      //debugger;
                      const DDTables = _tr.getElementsByTagName("select")[0];
                      DDTables.id = "s" + new Date().getTime();
                      for (const tbl of retTE) {
                        const opt2 = document.createElement("option");
                        opt2.text = tbl.parameters[FLD[0].name + "general"];
                        opt2.value = tbl._id;
                        DDTables.appendChild(opt2);
                      }

                      if (data.hasOwnProperty("row")) {
                        const type =
                          data.row.parameters[fld.name + "general"].type;
                        DDTables.value = type;

                        
                      } else {
                        const opt2 = document.createElement("option");
                        opt2.text = "--seleccionar--";
                        opt2.value = "selecc";
                        DDTables.insertBefore(opt2, DDTables.firstChild);
                        DDTables.value = "selecc";
                      }
                    }
                  );
                }
              );
              tbl.appendChild(_tr);

              for (const each of main.app["DDFields"]["all"].filter(
                (each) =>
                  each.parameters.Tipos_de_equipogeneral.type.includes(
                    "all"
                  ) === false
              )) {
                const _tr_s = lpC.tags["string"].cloneNode(true);
                _tr_s.style.display = "none";
                _tr_s.setAttribute(
                  "tipos",
                  each.parameters.Tipos_de_equipogeneral.type.join()
                );
                const label = _tr_s.getElementsByTagName("label")[0];
                const input = _tr_s.getElementsByTagName("input")[0];
                input.id = each._id;
                label.innerHTML = each.parameters.Namegeneral + ":&nbsp;";
                if (data.hasOwnProperty("row")) {
                  try {
                    input.value = data.row.parameters[each.name + "general"];
                  } catch (error) {}
                }
                tbl.appendChild(_tr_s);
              }
            }
            break;
          case "options":
            const _tr = lpC.tags["options"].cloneNode(true);

            _tr.id = fld.name + "general";
            const parms = fld.parameters.optionsgeneral;

            appC2.lp_query(
              {
                type: "querySelect",
                table: "modelizer_items",
                query: { type: "5f994d5908425a1078a1b7b2", _id: parms.table },
              },
              async function (rett) {
                //acceso primer select
                const DDTables = _tr.getElementsByTagName("select")[0];
                if (parms.all !== "") {
                  const opt = document.createElement("option");
                  opt.text = parms.all;
                  opt.value = "all";
                  DDTables.appendChild(opt);
                }

                const FLD = await appC2.lp_queryAsync({
                  type: "querySelect",
                  table: "modelizer_items",
                  query: { _id: parms.field },
                });

                appC2.lp_query(
                  {
                    type: "querySelect",
                    table: "modelizer_items",
                    query: {
                      type: "5fbf3934eb78a202dc26d321",
                      parent: rett[0]["_id"],
                    },
                  },
                  function (retTE) {
                    //acceso primer select
                    //debugger;
                    const DDTables = _tr.getElementsByTagName("select")[0];
                    DDTables.id = "s" + new Date().getTime();
                    for (const tbl of retTE) {
                      const opt2 = document.createElement("option");
                      opt2.text = tbl.parameters[FLD[0].name + "general"];
                      opt2.value = tbl._id;
                      DDTables.appendChild(opt2);
                    }

                    DDTables.addEventListener("change", function (obj) {
                      const ddiv = obj.target.nextElementSibling;
                      // const ddiv = obj.target.getAttribute('div');

                      const _sp = document.createElement("span");
                      const optS =
                        obj.target.options[obj.target.options.selectedIndex];
                      _sp.className = "spanf";
                      _sp.innerHTML = "&nbsp;" + optS.text + "&nbsp;";
                      _sp.setAttribute("_id", obj.target.value);
                      optS.style.display = "none";
                      obj.target.value = "";

                      const nid = "d" + new Date().getTime();
                      _sp.id = nid;

                      const _bt = document.createElement("span");
                      _bt.className = "buttonf";
                      _bt.setAttribute("nid", nid);
                      _bt.setAttribute("select", obj.target.id);
                      _bt.innerHTML = "&nbsp;x&nbsp;";
                      _bt.addEventListener("click", function (tget) {
                        //span donde está el botón
                        const sp = document.getElementById(
                          tget.target.getAttribute("nid")
                        );
                        //value _id del option
                        const _id = sp.getAttribute("_id");

                        //busco el option del select para habilitarlo de nuevo
                        const _sel = document.getElementById(
                          tget.target.getAttribute("select")
                        );
                        const _opt = Object.values(_sel.options).find(
                          (each) => each.value === _id
                        );
                        if (_opt) {
                          _opt.style.display = "";
                        }

                        //quito el span donde está el botón
                        sp.parentNode.removeChild(sp);
                      });

                      _sp.appendChild(_bt);
                      ddiv.appendChild(_sp);
                    });

                    try {
                      if (data.hasOwnProperty("row")) {
                        const types =
                          data.row.parameters[fld.name + "general"].type;
                        if (types.length > 0) {
                          const ddiv = DDTables.nextElementSibling; //document.getElementById('div' + key);
                          const ssel = DDTables; //document.getElementById('sel' + key);
                          for (const tpe of types) {
                            const _sp = document.createElement("span");
                            const optS = Object.values(ssel.options).find(
                              (eo) => eo.value === tpe
                            );
                            _sp.className = "spanf";
                            _sp.innerHTML = "&nbsp;" + optS.text + "&nbsp;";
                            _sp.setAttribute("_id", optS.value);
                            optS.style.display = "none";

                            const nid = "sp" + tpe;
                            _sp.id = nid;

                            const _bt = document.createElement("span");
                            _bt.className = "buttonf";
                            _bt.setAttribute("nid", nid);
                            _bt.setAttribute("select", DDTables.id);
                            _bt.innerHTML = "&nbsp;x&nbsp;";
                            _bt.addEventListener("click", function (tget) {
                              //span donde está el botón
                              const sp = document.getElementById(
                                tget.target.getAttribute("nid")
                              );
                              //value _id del option
                              const _id = sp.getAttribute("_id");

                              //busco el option del select para habilitarlo de nuevo
                              const _sel = document.getElementById(
                                tget.target.getAttribute("select")
                              );
                              const _opt = Object.values(_sel.options).find(
                                (each) => each.value === _id
                              );
                              if (_opt) {
                                _opt.style.display = "";
                              }

                              //quito el span donde está el botón
                              sp.parentNode.removeChild(sp);
                            });

                            _sp.appendChild(_bt);
                            ddiv.appendChild(_sp);
                          }
                        }
                      }
                    } catch (error) {
                      //console.log(error);
                    }
                    DDTables.value = "";
                  }
                );
              }
            );

            tbl.appendChild(_tr);
            break;
          case "special":
            {
              switch (fld.parameters.specialgeneral) {
                case "periodicidad":
                  {
                    const _tr = lpC.tags["periodicidad"].cloneNode(true);
                    _tr.id = fld.name + "general";

                    if (data.hasOwnProperty("row")) {
                      console.log(fld, data.row);
                      const info = data.row.parameters[fld.name + "general"];
                      _tr.getElementsByTagName("select")[0].value =
                        info.periodo;
                      _tr.getElementsByTagName("input")[0].value = info.num;
                    }

                    tbl.appendChild(_tr);
                  }
                  break;
                case "critico":
                  {
                    const _tr = lpC.tags["periodicidad"].cloneNode(true);
                    _tr.id = fld.name + "general";

                    if (data.hasOwnProperty("row")) {
                      console.log(fld, data.row);
                      const info = data.row.parameters[fld.name + "general"];
                      _tr.getElementsByTagName("select")[0].value =
                        info.periodo;
                      _tr.getElementsByTagName("input")[0].value = info.num;
                    }

                    tbl.appendChild(_tr);
                  }
                  break;
                case "tipo_resultados":
                  console.log("vamos a ver", data);
                  const _tr = lpC.tags["tipo_resultados"].cloneNode(true);

                  _tr.id = fld.name + "general";
                  const parms = {
                    table: "64f89f153b6dcf0381eb46a0",
                    field: "64f9976e6f21bf0349caa835",
                  }; // fld.parameters.optionsgeneral;

                  appC2.lp_query(
                    {
                      type: "querySelect",
                      table: "modelizer_items",
                      query: {
                        type: "5f994d5908425a1078a1b7b2",
                        _id: parms.table,
                      },
                    },
                    async function (rett) {
                      //acceso primer select
                      const DDTables = _tr.getElementsByTagName("select")[0];

                      const FLD = await appC2.lp_queryAsync({
                        type: "querySelect",
                        table: "modelizer_items",
                        query: { _id: parms.field },
                      });

                      const retTE = await appC2.lp_queryAsync({
                        type: "querySelect",
                        table: "modelizer_items",
                        query: {
                          type: "5fbf3934eb78a202dc26d321",
                          parent: rett[0]["_id"],
                        },
                      });

                      DDTables.id = "s" + new Date().getTime();
                      for (const tbl of retTE) {
                        const opt2 = document.createElement("option");
                        opt2.text = tbl.parameters[FLD[0].name + "general"];
                        opt2.value = tbl._id;
                        DDTables.appendChild(opt2);
                      }

                      DDTables.addEventListener("change", function (obj) {
                        const text =
                          obj.target.options[obj.target.options.selectedIndex]
                            .text;
                        lpC["tipo_resultados"].build({
                          text,
                          _id: obj.target.value,
                        });
                        obj.target.value = "";
                      });

                      DDTables.value = "";

                      if (data.hasOwnProperty("row")) {
                        const values =
                          data.row.parameters[fld.name + "general"]["items"];
                        for (const vall of values) {
                          const text = retTE.find(
                            (each) => each._id === vall._id
                          ).parameters[FLD[0].name + "general"];
                          lpC["tipo_resultados"].build({
                            text,
                            _id: vall._id,
                            values: vall,
                          });
                        }
                      }
                    }
                  );

                  tbl.appendChild(_tr);

                  break;
                case "check":
                  {
                    const _tr_s = lpC.tags["check"].cloneNode(true);
                    _tr_s.id = fld.name + "general";

                    const label = _tr_s.getElementsByTagName("label")[0];
                    const input = _tr_s.getElementsByTagName("input")[0];

                    input.id = fld.name + "general";

                    label.innerHTML = fld.parameters.namegeneral + ":&nbsp;";
                    if (data.hasOwnProperty("row")) {
                      input.checked = data.row.parameters[fld.name + "general"];
                    }

                    tbl.appendChild(_tr_s);
                  }
                  break;
                case "string":
                  const _tr_s = lpC.tags["string"].cloneNode(true);

                  const label = _tr_s.getElementsByTagName("label")[0];
                  const input = _tr_s.getElementsByTagName("input")[0];

                  input.id = fld.name + "general";

                  /*
                                    const tr = document.createElement("tr");
                                    const tdl = document.createElement("td");
                                    tdl.style.textAlign = "right";
                                    const label = document.createElement("label");
                                    label.innerHTML = fld.parameters.namegeneral + ':&nbsp;';
                                    tdl.appendChild(label);
                                    tr.appendChild(tdl)

                                    const tdi = document.createElement("td");
                                    const input = document.createElement("input");

                                    tdi.appendChild(input);
                                    tr.appendChild(tdi);

                                    tbl.appendChild(tr);
                                    */

                  label.innerHTML = fld.parameters.namegeneral + ":&nbsp;";
                  if (data.hasOwnProperty("row")) {
                    input.value = data.row.parameters[fld.name + "general"];
                  }

                  tbl.appendChild(_tr_s);
                  break;
              }
            }
            break;
          case "check":
            {
              const _tr_s = lpC.tags["string"].cloneNode(true);
              const label = _tr_s.getElementsByTagName("label")[0];
              const input = _tr_s.getElementsByTagName("input")[0];
              input.id = fld.name + "general";

              label.innerHTML = fld.parameters.namegeneral + ":&nbsp;";
              if (data.hasOwnProperty("row")) {
                input.checked = data.row.parameters[fld.name + "general"];
              }
            }

            tbl.appendChild(_tr_s);
            break;
          case "string":
            const _tr_s = lpC.tags["string"].cloneNode(true);

            const label = _tr_s.getElementsByTagName("label")[0];
            const input = _tr_s.getElementsByTagName("input")[0];

            input.id = fld.name + "general";

            /*
                        const tr = document.createElement("tr");
                        const tdl = document.createElement("td");
                        tdl.style.textAlign = "right";
                        const label = document.createElement("label");
                        label.innerHTML = fld.parameters.namegeneral + ':&nbsp;';
                        tdl.appendChild(label);
                        tr.appendChild(tdl)

                        const tdi = document.createElement("td");
                        const input = document.createElement("input");

                        tdi.appendChild(input);
                        tr.appendChild(tdi);

                        tbl.appendChild(tr);
                        */

            label.innerHTML = fld.parameters.namegeneral + ":&nbsp;";
            if (data.hasOwnProperty("row")) {
              input.value = data.row.parameters[fld.name + "general"];
            }

            tbl.appendChild(_tr_s);
            break;
        }
      }
      break;
  }
};
